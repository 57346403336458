import { currentStoreView } from '@vue-storefront/core/lib/multistore'

const weglot = function () {
  /* eslint-disable */
  window.addEventListener ("load", () => {
    if (window.location.host !== 'www.zaun24.de') {
      // return;
    }

    var s = document.createElement( 'script' );
    s.setAttribute( 'src', 'https://cdn.weglot.com/weglot.min.js' );
    s.onload=function() {
      if (typeof Weglot !== 'undefined') {
        Weglot.initialize({
          api_key: 'wg_2d7bcc26aca769fcfca73d24414b53a31'
        });
      }
    };
    document.body.appendChild( s );
  });
  /* eslint-enable */
}

const chatbase = function () {
  /* eslint-disable */
  const onConsent = function (cb) {
      if (!window.UC_UI || !UC_UI.isInitialized()) {
          window.addEventListener('UC_UI_INITIALIZED', function() {
              cb();
          });
      } else {
          cb();
      }
  }
  const getConsent = async () => {
    try {
        return window.__ucCmp.getConsentDetails().then(details => details.services['C66l3JRfAxdGQA'].consent.given)
    } catch (e) {
        return false;
    }
  }
  const insertChatbase = () => {
	  // Step 1: Create a configuration object for the chatbot
    const chatbotConfigScript = document.createElement('script');
    chatbotConfigScript.innerHTML = `
        window.embeddedChatbotConfig = {
            chatbotId: "yF_rXRDprb4LLYOQICUI0",
            domain: "www.chatbase.co"
        };
    `;

    // Step 2: Append the configuration script to the document
    document.head.appendChild(chatbotConfigScript);

    // Step 3: Create the script to load the chatbot from the external source
    const chatbotScript = document.createElement('script');
    chatbotScript.src = "https://www.chatbase.co/embed.min.js";
    chatbotScript.setAttribute('chatbotId', 'yF_rXRDprb4LLYOQICUI0');
    chatbotScript.setAttribute('domain', 'www.chatbase.co');
    chatbotScript.defer = true;
    chatbotScript.async = true;

    // Step 4: Append the chatbot loading script to the document
    document.body.appendChild(chatbotScript);
  }

  document.addEventListener('DOMContentLoaded', () => {
    onConsent(async () => {
      consent = await getConsent();
      if (consent) {
        insertChatbase();
      }
    });
  });
  /* eslint-enable */
}

const metaInfo = {
  title: 'Zäune und Gartentore online kaufen bei ZAUN24',
  titleTemplate: '%s',
  htmlAttrs: {
    lang: 'de'
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: 'Qualitativ hochwertige Zäune, Garten- und Einfahrtstore auf Wunsch mit E-Antrieb für Privat und Gewerbe. Kostenlose Beratung, freie Lieferung ab 350€' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, minimal-ui, maximum-scale=1' },
    { name: 'robots', content: 'index, follow' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-32x32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-16x16.png', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '/assets/apple-touch-icon.png' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_2048.png', sizes: '2048x2732' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1668.png', sizes: '1668x2224' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1536.png', sizes: '1536x2048' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1125.png', sizes: '1125x2436' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1242.png', sizes: '1242x2208' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_750.png', sizes: '750x1334' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_640.png', sizes: '640x1136' },
    { rel: 'manifest', href: '/assets/manifest.json' },
    { rel: 'preconnect', href: 'https://app.usercentrics.eu/', crossorigin: 'anonymous' },
    { rel: 'dns-prefetch', href: 'https://app.usercentrics.eu/', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: 'https://graphql.usercentrics.eu/', crossorigin: 'anonymous' },
    { rel: 'dns-prefetch', href: 'https://graphql.usercentrics.eu/', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: 'https://api.usercentrics.eu/', crossorigin: 'anonymous' },
    { rel: 'dns-prefetch', href: 'https://api.usercentrics.eu/', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: 'https://cdn.jsdelivr.net', crossorigin: 'anonymous' },
    { rel: 'dns-prefetch', href: 'https://cdn.jsdelivr.net', crossorigin: 'anonymous' }
  ],
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/pwacompat',
      async: true,
      crossorigin: 'anonymous'
    },
    {
      id: 'usercentrics-cmp',
      src: 'https://web.cmp.usercentrics.eu/ui/loader.js',
      'data-settings-id': (currentStoreView().storeCode === 'zaun24_at') ? 'CDMSBmxXd' : 'N-IPWS4uj',
      'data-tcf-enabled': true,
      async: true
    },
    {
      id: 'easycredit',
      src: 'https://ratenkauf.easycredit.de/api/resource/webcomponents/v3/easycredit-components/easycredit-components.esm.js',
      defer: true,
      type: 'module'
    },
    {
      vmid: 'weglot',
      innerHTML: '(' + weglot.toString() + '());',
      type: 'text/javascript'
    },
    {
      vmid: 'chatbase',
      innerHTML: '(' + chatbase.toString() + '());',
      type: 'text/javascript'
    }
  ],
  __dangerouslyDisableSanitizersByTagID: {
    weglot: ['innerHTML'],
    chatbase: ['innerHTML']
  }
}
export default metaInfo
